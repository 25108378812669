<template>
  <div class="w-full">
    <div class="p-4 border-b border-gray-50">
      <fw-heading size="h3">Editor</fw-heading>
    </div>
    <div class="flex gap-2 px-4 py-3 flex-wrap w-full">
      <div class="w-full flex flex-col items-stretch">
        <fw-label>Estilo</fw-label>
        <b-dropdown
          class="w-full text-editor-styles"
          :scrollable="true"
          :disabled="blockType != 'text'"
          :max-height="250"
          aria-role="list"
          position="is-bottom-right"
        >
          <template #trigger="{}">
            <fw-button class="mt-1 h-12" expanded type="dropdown">
              <div class="flex-1 text-left" :class="[currentStyle.style]">{{ currentStyle.name }}</div>
              <fw-icon-chevron-down />
            </fw-button>
          </template>
          <b-dropdown-item
            v-for="(style, v) in styles"
            :key="'style_' + v"
            aria-role="listitem"
            @click="editorCommand(style.style)"
          >
            <div :class="[style.style]">
              {{ style.name }}
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="w-full flex flex-col items-stretch">
        <fw-label>Formatação</fw-label>
        <div class="flex gap-2 mb-2">
          <fw-button
            :disabled="!optionsActive"
            :type="active['bold'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('bold')"
          >
            <fw-icon-bold class="h-5 w-5"></fw-icon-bold>
          </fw-button>

          <fw-button
            :disabled="!optionsActive"
            :type="active['italic'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('italic')"
          >
            <fw-icon-italic class="h-5 w-5"></fw-icon-italic>
          </fw-button>

          <fw-button
            :disabled="!optionsActive"
            :type="active['underline'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('underline')"
          >
            <fw-icon-underline class="h-5 w-5"></fw-icon-underline>
          </fw-button>

          <fw-button
            :disabled="!optionsActive"
            :type="active['strike'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('strike')"
          >
            <fw-icon-strike class="h-5 w-5"></fw-icon-strike>
          </fw-button>
        </div>
        <div class="flex gap-2">
          <fw-button
            :disabled="!optionsActive"
            :type="active['subscript'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('subscript')"
          >
            <fw-icon-subscript class="h-5 w-5"></fw-icon-subscript>
          </fw-button>
          <fw-button
            :disabled="!optionsActive"
            :type="active['superscript'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('superscript')"
          >
            <fw-icon-superscript class="h-5 w-5"></fw-icon-superscript>
          </fw-button>

          <fw-button
            :disabled="!optionsActive"
            :type="active['bulletlist'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('bulletlist')"
          >
            <fw-icon-unorderedlist class="h-5 w-5"></fw-icon-unorderedlist>
          </fw-button>
          <fw-button
            :disabled="!optionsActive"
            :type="active['orderedlist'] ? 'primary' : 'xlight'"
            @click.native="editorCommand('orderedlist')"
          >
            <fw-icon-orderedlist class="h-5 w-5"></fw-icon-orderedlist>
          </fw-button>
        </div>
      </div>
    </div>
    <!--<div v-if="blockType === 'heading'" class="flex gap-2 p-2 flex-wrap w-full">
      <fw-button :type="selected == 'h1' ? 'primary' : 'xlight'" @click.native="editorCommand('h1')">
        <fw-icon-heading1 class="h-5 w-5"></fw-icon-heading1>
      </fw-button>
      <fw-button :type="selected == 'h2' ? 'primary' : 'xlight'" @click.native="editorCommand('h2')">
        <fw-icon-heading2 class="h-5 w-5"></fw-icon-heading2>
      </fw-button>
      <fw-button :type="selected == 'h3' ? 'primary' : 'xlight'" @click.native="editorCommand('h3')">
        <fw-icon-heading3 class="h-5 w-5"></fw-icon-heading3>
      </fw-button>
    </div>-->
  </div>
</template>
<script>
export default {
  name: 'PanelSideEditor',
  props: {
    editorCommand: {
      type: Function,
      required: true,
    },
    blockType: {
      type: String,
    },
    active: {
      type: Object,
    },
  },
  data() {
    return {
      styles: [
        {
          style: 'h1',
          name: 'Título',
        },
        {
          style: 'h2',
          name: 'Subtítulo',
        },
        {
          style: 'h3',
          name: 'Sub-subtítulo',
        },
        {
          style: 'meta',
          name: 'Metadados',
        },
        {
          style: 'paragraph',
          name: 'Parágrafo',
        },
        {
          style: 'codeblock',
          name: 'Código',
        },
      ],
    }
  },
  computed: {
    optionsActive() {
      return this.blockType == 'text' && this.currentStyle != null && this.currentStyle.style == 'paragraph'
    },
    currentStyle() {
      let selectedStyle = this.active.style ?? 'paragraph'
      return this.styles.find(style => style.style === selectedStyle)
    },
  },
}
</script>
<style scoped>
.text-editor-styles .h1 {
  font-size: 1.5rem;
  font-weight: 700;
}
.text-editor-styles .h2 {
  font-size: 1.25rem;
  font-weight: 700;
}
.text-editor-styles .h3 {
  font-size: 1rem;
  font-weight: 700;
}
.text-editor-styles .meta {
  font-size: 0.75rem;
  font-weight: 700;
  color: #616161;
}
.text-editor-styles .paragraph {
  font-size: 1rem;
  font-weight: 400;
}
.text-editor-styles .codeblock {
  background: #0d0d0d;
  color: #fff;
  font-family: 'JetBrainsMono', monospace;
  font-weight: 400;
  border-radius: 0.5rem;
  line-height: 2rem;
  padding: 0rem 0.5rem;
}
</style>
